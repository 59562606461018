import type { VirtualElement as IVirtualElement } from '@popperjs/core';
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { useTheme } from 'styled-components';

const useLazyRef = (initialValFunc: () => any) => {
  const ref: MutableRefObject<any> = useRef(null);
  if (ref.current === null) {
    ref.current = initialValFunc();
  }
  return ref;
};

export interface PopperPortalProps {
  visible?: boolean;
  locked?: boolean;
  children: ReactNode;
}

class VirtualElement implements IVirtualElement {
  private rect = {
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    x: 0,
    y: 0,
    toJSON() {
      return this;
    },
  };

  update(x: number, y: number) {
    this.rect.y = y;
    this.rect.top = y;
    this.rect.bottom = y;

    this.rect.x = x;
    this.rect.left = x;
    this.rect.right = x;
  }

  getBoundingClientRect(): DOMRect {
    return this.rect;
  }
}

const POPPER_OPTIONS: Parameters<typeof usePopper>[2] = {
  placement: 'right-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [8, 8],
      },
    },
  ],
};

const MouseTooltip = ({ visible = true, locked = false, children }: PopperPortalProps) => {
  const [portal_element, setPortalElement] = useState<HTMLDivElement>();
  const [popper_element, setPopperElement] = useState<HTMLDivElement | null>();
  const virtual_element_ref = useLazyRef(() => new VirtualElement());
  const theme = useTheme();

  const { styles, attributes, update } = usePopper(
    virtual_element_ref.current,
    popper_element,
    POPPER_OPTIONS,
  );

  useEffect(() => {
    const el = document.createElement('div');
    document.body.appendChild(el);
    setPortalElement(el);

    return () => el.remove();
  }, []);

  useEffect(() => {
    const handler = ({ clientX: x, clientY: y }) => {
      virtual_element_ref.current.update(x, y);
      if (!visible || locked) return;
      update?.();
    };

    document.addEventListener('mousemove', handler);

    update?.();

    return () => {
      document.removeEventListener('mousemove', handler);
    };
  }, [update, locked]);

  useEffect(() => {
    if (!visible) return;
    update?.();
  }, [visible, update]);

  if (!portal_element || !visible) return null;

  return createPortal(
    <div
      ref={setPopperElement}
      {...attributes.popper}
      style={{
        ...styles.popper,
        zIndex: theme.zindex.popover,
      }}>
      {children}
    </div>,
    portal_element,
  );
};

export default MouseTooltip;
