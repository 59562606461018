import styled, { css } from 'styled-components';

import { Div, StyledUtilsProps } from '../helpers/StyledUtils';
import { ReactNode } from 'react';

interface Props extends StyledUtilsProps {
  options: {
    label: ReactNode;
    key: string | boolean;
  }[];
  active: string | boolean;
  onSelect: (key: string | boolean) => void;
}

const StyledTextSwitchWrapper = styled(Div)`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(0.25)};
  border: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.colors.surface.container.neutral};
  border-radius: ${({ theme }) => theme.radius.normal};
`;

const StyledTextSwitch = styled.button<{
  active: boolean;
}>(
  ({ theme, active }) => css`
    border: none;
    background-color: unset;
    position: relative;
    border-radius: ${theme.radius.normal};

    white-space: nowrap;
    line-height: ${theme.pxToRem(20)};
    font-weight: ${theme.font_weigths.medium};
    padding: ${theme.spacing(0.75)} ${theme.spacing(1.75)};
    border: 1px solid transparent;
    text-decoration: none;
    color: ${active
      ? theme.colors.on.neutral.primary_neutral
      : theme.colors.on.neutral.secondary_neutral};

    border-radius: ${theme.radius.normal_inset};
    &:hover,
    & > *:hover {
      color: ${theme.colors.on.neutral.primary_neutral};
      cursor: pointer;
    }
    ${active &&
    css`
      border: ${({ theme }) => theme.border};
      background-color: ${theme.colors.surface.base.surface};
    `};
  `,
);

const TextSwitch = ({ options, active, onSelect, ...props }: Props) => {
  return (
    <StyledTextSwitchWrapper {...props}>
      {options.map((option) => (
        <StyledTextSwitch
          type="button"
          key={String(option.key)}
          active={option.key === active}
          onClick={() => onSelect(option.key)}>
          {option.label}
        </StyledTextSwitch>
      ))}
    </StyledTextSwitchWrapper>
  );
};

export default TextSwitch;
