import {
  CartesianGrid,
  Line,
  LineChart as RechartLineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';

import { Fragment } from 'react';
import { CurveType } from 'recharts/types/shape/Curve';
import Chart, { ChartProps, CLASS_PREFIX } from './Chart';

const DASHED_LINE = '4 8';

const LineChart: React.FC<Omit<ChartProps, 'type' | 'children'>> = (props) => {
  const theme = useTheme();

  const allow_decimals = !props.datasets?.some((dataset) =>
    dataset.data.some((data) => Number(data.y) > 1),
  );

  return (
    <Chart type={'line'} {...props}>
      {({
        id,
        data,
        cached_datasets,
        datasets,
        hovered,
        locked_state,
        priority_display,
        active_name,
        setHovered,
        setActiveName,
      }) => (
        <RechartLineChart
          margin={{ top: 0, left: 20, right: 0, bottom: 0 }}
          data={data}
          onMouseMove={(e) => {
            if (!active_name && !e) {
              return;
            }
            setActiveName(e?.activeLabel || null);
          }}>
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={theme.colors.outline.neutral}
            vertical={false}
          />
          <XAxis
            dataKey={'name'}
            allowDecimals={false}
            stroke={'transparent'}
            tick={{ fill: theme.colors.on.neutral.secondary_neutral, fontWeight: 500 }}
            tickMargin={8}
            minTickGap={props.compact ? 32 : 64}
            ticks={priority_display}
          />
          {!props.compact && (
            <YAxis
              width={58}
              allowDecimals={allow_decimals}
              stroke={'transparent'}
              domain={
                cached_datasets &&
                cached_datasets[0] &&
                cached_datasets[0].metric_type === 'percent'
                  ? [0, 'auto']
                  : ['minData', allow_decimals ? 1 : 'auto']
              }
              tickMargin={8}
              minTickGap={24}
              tick={{ fill: theme.colors.on.neutral.secondary_neutral, fontWeight: 500 }}
            />
          )}
          <Tooltip
            wrapperStyle={{
              outline: 'none',
              pointerEvents: locked_state ? 'auto' : 'none',
            }}
            cursor={!!active_name}
            trigger={locked_state ? 'click' : 'hover'}
            active={false}
            content={() => null}
          />
          {(hovered && datasets
            ? [...datasets].sort((a) => (a.key === hovered.key ? 1 : -1))
            : datasets
          )?.map((dataset) => (
            <Fragment key={dataset.key}>
              {(() => {
                const props = {
                  className: `${CLASS_PREFIX}-${id} ${CLASS_PREFIX}-${id}-${dataset.key}`,
                  type: 'linear' as CurveType,
                  dataKey: dataset.label,
                  stroke: dataset.theme_color
                    ? theme.colors.surface.chart[dataset.theme_color]
                    : dataset.hex_color,
                  isAnimationActive: false,
                  dot: false,
                };

                return (
                  <>
                    {hovered && hovered.key !== dataset.key ? (
                      <Line
                        type={'linear'}
                        strokeWidth={2}
                        dataKey={dataset.label}
                        stroke={theme.colors.outline.neutral}
                        isAnimationActive={false}
                        dot={false}
                      />
                    ) : (
                      <>
                        <Line
                          {...props}
                          strokeWidth={2}
                          connectNulls
                          style={{ transition: 'stroke-opacity 300ms', opacity: 0.5 }}
                          strokeDasharray={DASHED_LINE}
                          strokeLinecap={'round'}
                          strokeLinejoin={'round'}
                        />
                        <Line
                          {...props}
                          strokeWidth={2}
                          onMouseEnter={() =>
                            setHovered({ key: dataset.key, label: dataset.label })
                          }
                          onMouseLeave={() => setHovered(null)}
                          activeDot={!!active_name}
                          style={{ transition: 'stroke-opacity 300ms' }}
                          {...(dataset.dashed
                            ? {
                                dot: false,
                                strokeDasharray: DASHED_LINE,
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                              }
                            : {})}
                        />
                      </>
                    )}
                  </>
                );
              })()}
            </Fragment>
          ))}
        </RechartLineChart>
      )}
    </Chart>
  );
};

export default LineChart;
