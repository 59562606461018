import React from 'react';

import Container from './base/Container';
import Icon from './base/Icon';
import Text from './base/Text';
import { Div, StyledUtilsProps } from './helpers/StyledUtils';

interface LongLoaderProps extends StyledUtilsProps {
  message: string;
}

const Loader: React.FC<LongLoaderProps> = ({ message, ...rest }) => {
  return (
    <Div flex={{ direction: 'column', align: 'center', justify: 'center' }} {...rest}>
      <Icon icon={'loading'} m={{ b: 2 }} />
      <Container w={{ px: 336 }}>
        <Text p={{ t: 1 }} muted center>
          {message}
        </Text>
      </Container>
    </Div>
  );
};

export default Loader;
