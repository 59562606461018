import styled, { css, useTheme } from 'styled-components';

import Divider from '../base/Divider';
import Skeleton, { pulse_keyframe } from '../base/Skeleton';
import Text from '../base/Text';
import { Div } from '../helpers/StyledUtils';
import LegendColor from './LegendColor';

interface BarChartProps {
  type: 'bar' | 'line';
  legend_placeholder?: string;
  height?: number;
  loading?: boolean;
}

const StyledSkeletonChart = styled.div<{ $loading: boolean }>(
  ({ $loading }) => css`
    ${$loading &&
    css`
      animation: ${pulse_keyframe} 1.5s ease-in-out 0.5s infinite;
    `};
  `,
);

const StyledGrid = styled(Div)<{ columns: number }>(
  ({ columns }) => css`
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(${columns}, 1fr);
    width: 100%;
  `,
);

const StyledCell = styled.div<{ type: 'bar' | 'line' }>(
  ({ theme, type }) => css`
    width: 100%;
    height: 100%;
    border: 1px solid ${theme.colors.outline.neutral};

    ${type === 'bar' &&
    css`
      &:nth-child(-n + 16) {
        border: 1px dashed ${theme.colors.outline.neutral};
        border-left: none;
        border-right: none;
      }
      &:nth-last-child(-n + 4) {
        border-top: 1px dashed ${theme.colors.outline.neutral};
      }
      border-left: none;
      border-right: none;
    `}
  `,
);

const StyledLine = styled(Div)(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: 12px;
    border: 1px solid ${theme.colors.outline.neutral};
    border-bottom: none;
    border-top: none;
  `,
);

const SkeletonChart: React.FC<BarChartProps> = ({
  type,
  legend_placeholder,
  height = 374,
  loading = false,
}) => {
  const theme = useTheme();

  return (
    <StyledSkeletonChart $loading={loading}>
      {legend_placeholder && (
        <>
          <Div flex={{ justify: 'space-between' }} p={4}>
            <Div p={{ x: 1 }} flex={{ align: 'center' }}>
              <LegendColor color={theme.colors.outline.neutral} m={{ r: 2 }} />
              <Text monospace muted>
                {legend_placeholder}
              </Text>
            </Div>
          </Div>
          <Divider />
        </>
      )}
      <Div p={{ r: 5, t: 7, b: 5 }}>
        <Div flex={{ direction: 'column' }} h={{ px: height }} m={{ l: 5 }}>
          <Div flex={{ grow: true }}>
            <StyledGrid columns={4}>
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
              <StyledCell type={type} />
            </StyledGrid>
          </Div>
          <StyledGrid columns={4} m={{ b: 1 }} h={{ px: 28 }}>
            <StyledLine>
              <Skeleton w={{ px: 80 }} xsmall style={{ position: 'absolute', top: '16px' }} />
            </StyledLine>
            <StyledLine>
              <Skeleton
                w={{ px: 80 }}
                xsmall
                style={{ position: 'absolute', top: '16px', transform: 'translateX(-40px)' }}
              />
            </StyledLine>
            <StyledLine>
              <Skeleton
                w={{ px: 80 }}
                xsmall
                style={{ position: 'absolute', top: '16px', transform: 'translateX(-40px)' }}
              />
            </StyledLine>
            <StyledLine>
              <Div
                flex={{ justify: 'space-between' }}
                style={{ position: 'absolute', top: '16px' }}
                w={{ raw: '100%' }}>
                <Skeleton w={{ px: 80 }} xsmall style={{ transform: 'translateX(-40px)' }} />
                <Skeleton w={{ px: 80 }} xsmall />
              </Div>
            </StyledLine>
          </StyledGrid>
        </Div>
      </Div>
    </StyledSkeletonChart>
  );
};

export default SkeletonChart;
