import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { ClickableArea } from '../base/Button';
import { StyledCard, StyledCardSection } from '../base/Card';
import Text from '../base/Text';
import ScrollArea from '../helpers/ScrollArea';
import { Div } from '../helpers/StyledUtils';
import Search from '../Search';
import { ChartDataSet, ChartHoveredState } from './Chart';
import LegendColor from './LegendColor';

interface ChartTooltipProps {
  label: string;
  monospace: boolean;
  data: [string, string][];
  datasets: ChartDataSet[];
  hovered?: string;
  locked?: boolean;

  setHover: Dispatch<SetStateAction<ChartHoveredState | null>>;
  setLockedState: Dispatch<SetStateAction<{ hovered?: ChartHoveredState | null }>>;
}

const filterData = (data: [string, string][], search_term: string) => {
  if (!search_term) {
    return data;
  }

  const filtered_data = data.reduce((prev: [string, string][], [k, v]) => {
    if (k.toLowerCase().includes(search_term.toLowerCase())) {
      prev.push([k, v]);
    }

    return prev;
  }, []);

  return filtered_data;
};

const ChartTooltip: React.FC<ChartTooltipProps> = ({
  label,
  data,
  monospace,
  datasets,
  hovered,
  locked = false,
  setHover,
  setLockedState,
}) => {
  const theme = useTheme();
  const [filtered_data, setFilteredData] = useState(data);
  const [search_term, setSearchTerm] = useState('');

  useEffect(() => {
    setFilteredData(filterData(data, search_term));
  }, [data]);

  const handleSearch = (value) => {
    const filtered_data = filterData(data, value);

    if (value) {
      if (!filtered_data.length) {
        return;
      }
      const dataset = datasets.find((d) => d.label === filtered_data[0][0]);
      if (!dataset) {
        return;
      }
      const hovered_state = {
        key: dataset.key,
        label: dataset.label,
      };
      setHover(hovered_state);
      setLockedState((prev) => ({
        ...(prev || {}),
        hovered: hovered_state,
        searched: true,
      }));
    } else {
      setLockedState((prev) => ({
        ...(prev || {}),
        hovered: null,
        searched: false,
      }));
    }

    setSearchTerm(value);
    setFilteredData(filtered_data);
  };

  return (
    <StyledCard
      style={{ pointerEvents: locked ? 'auto' : 'none', overflow: 'hidden' }}
      raised
      min_w={{ px: 248 }}
      onClick={(e) => e.stopPropagation()}
      onMouseMove={(e) => e.stopPropagation()}>
      <StyledCardSection p={{ x: 3, y: 2 }}>
        <Text heading>{label}</Text>
        {(search_term || filtered_data?.length > 4) && (
          <Search small m={{ t: 2 }} focus value={search_term} onChange={handleSearch} />
        )}
      </StyledCardSection>
      <ScrollArea max_h={{ px: 196 }}>
        {filtered_data
          .sort((a, b) => (a[1] > b[1] ? -1 : 1))
          .map(([k, value], i) => {
            const dataset = datasets.find((d) => d.label === k);
            return (
              <StyledCardSection
                key={`${k}${i}`}
                style={i === 0 ? { borderRadius: 0 } : undefined}
                onMouseMove={(e) => {
                  if (!locked) {
                    return;
                  }
                  e.stopPropagation();
                }}
                onMouseEnter={(e) => {
                  if (!locked) {
                    return;
                  }
                  e.stopPropagation();
                  setHover({ key: dataset!.key, label: dataset!.label, tooltip: true });
                }}
                onMouseLeave={() => setHover(null)}>
                <ClickableArea
                  as="div"
                  key={`${k}${i}`}
                  flex={{ justify: 'space-between', align: 'center' }}
                  p={{ x: 3, y: 2 }}>
                  <Div flex={{ align: 'center' }}>
                    <LegendColor
                      color={
                        dataset!.theme_color
                          ? theme.colors.surface.chart[dataset!.theme_color]
                          : dataset!.hex_color
                      }
                      m={{ r: 2 }}
                    />
                    <Text
                      text_wrap={false}
                      monospace={monospace}
                      subtitle={!monospace}
                      size="s"
                      dark={hovered === dataset!.key}
                      muted={hovered ? hovered !== dataset!.key : false}>
                      {k}
                    </Text>
                  </Div>
                  <Text
                    text_wrap={false}
                    dark={hovered === dataset!.key}
                    muted={hovered ? hovered !== dataset!.key : false}
                    m={{ l: 4 }}>
                    {isNaN(parseFloat(value)) ? 'N/A' : dataset?.getDataLabel?.(value) ?? value}
                  </Text>
                </ClickableArea>
              </StyledCardSection>
            );
          })}
      </ScrollArea>
    </StyledCard>
  );
};

export default ChartTooltip;
