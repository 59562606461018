import { useEffect, useState } from 'react';

interface WithLoadingProps {
  loading: boolean;
  loading_element: React.ReactNode;
  long_loading_element?: React.ReactNode;
  children: React.ReactNode;
}

const WithLoader: React.FC<WithLoadingProps> = ({
  loading,
  loading_element,
  long_loading_element,
  children,
}) => {
  const [show_long_loader, setShowLongLoader] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setShowLongLoader(true);
    }, 10000);

    return () => {
      clearTimeout(handler);
    };
  }, [loading]);

  if (loading) {
    return <>{long_loading_element && show_long_loader ? long_loading_element : loading_element}</>;
  }

  if (show_long_loader) {
    setShowLongLoader(false);
  }

  return <>{children}</>;
};

export default WithLoader;
