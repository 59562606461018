import { JSXElementConstructor, ReactElement, useEffect, useId, useMemo, useState } from 'react';
import { ResponsiveContainer } from 'recharts';
import { useTheme } from 'styled-components';

import { capitalizeFirstLetter } from '../../../utils';
import isEmpty from '../../../utils/isEmpty';
import Button, { ClickableArea } from '../base/Button';
import { StyledCard, StyledCardSection } from '../base/Card';
import Divider from '../base/Divider';
import Text from '../base/Text';
import ScrollArea from '../helpers/ScrollArea';
import { Div } from '../helpers/StyledUtils';
import Loader from '../Loader';
import { MetricType } from '../metrics/metric-definitions';
import MouseTooltip from '../MouseTooltip';
import WithLoader from '../WithLoader';
import ChartTooltip from './ChartTooltip';
import LegendColor from './LegendColor';
import SkeletonChart from './SkeletonChart';
import TextSwitch from '../base/TextSwitch';

export const CLASS_PREFIX = 'hookdeck-custom-recharts';

type OrderBy = 'total' | 'highest' | 'average';

const order_by_by_metric_type: { [key in MetricType]: OrderBy } = {
  count: 'total',
  percent: 'highest',
  rate: 'average',
  avg: 'average',
  max: 'highest',
};

interface Data {
  [key: string]: string;
  name: string;
}

export type ChartDatapoint = {
  priority_display?: boolean;
  x: number | string;
  y: number | string;
};

export type ChartDataSet = {
  key: string;
  label: string;
  monospace?: boolean;
  theme_color?: 'primary' | 'success' | 'danger';
  hex_color?: string;
  data: ChartDatapoint[];
  total: number;
  highest: number;
  average: number;
  dashed?: boolean;
  metric_type: MetricType;
  getDataLabel?: (v: string | number) => string | number;
};

export interface ChartTimeOptions {
  time_unit: string;
  time_units: { key: string; label: string }[];
  onTimeUnitSelected: (unit: string) => void;
}

interface ChartConfig {
  id: string;
  data: Data[];
  cached_datasets: ChartDataSet[] | null;
  datasets: ChartDataSet[] | null;
  hovered: ChartHoveredState | null;
  active_name: string | null;
  locked_state: { hovered?: ChartHoveredState; active_name?: string } | null;

  priority_display?: (string | number)[];

  setHovered: (hovered: ChartHoveredState | null) => void;
  setActiveName: (name: string | null) => void;
}

type ChartType = 'line' | 'bar';

export interface ChartProps {
  type: ChartType;
  legend_placeholder?: string;
  compact?: boolean;
  datasets: ChartDataSet[] | null;
  height?: number;
  loading?: boolean;
  children: (props: ChartConfig) => ReactElement<any, string | JSXElementConstructor<any>>;
  onRefresh?: () => void;
  time_options?: ChartTimeOptions;
}

export interface ChartHoveredState {
  key: string;
  label: string;
  name?: string;
  monospace?: boolean;
  tooltip?: boolean;
}

const formatDataForRechart = (datasets: ChartDataSet[]): Data[] => {
  const merged_data: any = [];
  datasets.forEach((dataset) => {
    dataset.data.forEach((data, i) => {
      if (!merged_data[i]) {
        merged_data[i] = {};
      }
      merged_data[i].name = data.x;
      merged_data[i][dataset.label] = data.y;
    });
  });
  return merged_data;
};

const LegendTooltip = ({
  hovered,
  datasets,
}: {
  hovered: ChartHoveredState;
  datasets: ChartDataSet[];
}) => {
  const theme = useTheme();

  if (!hovered) {
    return null;
  }

  const dataset = datasets.find((d) => d.key === hovered.key) as ChartDataSet;

  const label = capitalizeFirstLetter(order_by_by_metric_type[dataset.metric_type]);
  let value = dataset.getDataLabel?.(dataset[order_by_by_metric_type[dataset.metric_type]]);

  if (typeof value === 'number' && isNaN(value)) {
    value = 'N/A';
  }

  const data = [
    {
      label,
      value,
    },
  ];

  return (
    <StyledCard raised={3} min_w={{ px: 248 }}>
      <StyledCardSection p={{ x: 3, y: 2 }}>
        <Div flex={{ align: 'center', justify: 'space-between' }}>
          <Div style={{ zIndex: 2 }} flex={{ align: 'center', gap: 2 }}>
            <LegendColor
              color={
                dataset.theme_color
                  ? theme.colors.surface.chart[dataset.theme_color]
                  : dataset.hex_color
              }
            />
            <Text
              monospace={dataset.monospace === false ? false : true}
              subtitle={dataset.monospace === false ? true : false}
              size="s"
              text_wrap={false}>
              {hovered?.label}
            </Text>
          </Div>
        </Div>
      </StyledCardSection>
      {data.map(({ label, value }) => (
        <StyledCardSection
          flex={{ align: 'center', justify: 'space-between' }}
          p={{ x: 3, y: 2 }}
          key={label}>
          <Text subtitle m={{ r: 2 }}>
            {label}
          </Text>
          <Text subtitle>{value}</Text>
        </StyledCardSection>
      ))}
    </StyledCard>
  );
};

const legendSorter = (a: ChartDataSet, b: ChartDataSet, order_by: OrderBy) => {
  const a_value = a[order_by];
  const b_value = b[order_by];

  return a_value > b_value ? -1 : 1;
};

export const formatActiveNameForDom = (name: string) => {
  return name.replace(/ /g, '-').replace(/:/g, '-').replace(/,/g, '');
};

// handle color change inside the DOM to avoid re-rendering the whole chart
const handleHovered = (
  id: string,
  type: ChartType,
  hovered: ChartHoveredState,
  active_name: string | null,
) => {
  const name = active_name && formatActiveNameForDom(active_name);
  const css_property = type === 'line' ? 'stroke-opacity' : 'opacity';
  if (
    hovered ||
    (type === 'bar' &&
      name &&
      document.body.querySelectorAll(`.${CLASS_PREFIX}-${id}-${name}`)?.length)
  ) {
    document.body.querySelectorAll(`.${CLASS_PREFIX}-${id}`)?.forEach((el: HTMLElement) => {
      if (
        (hovered && el.classList.contains(`${CLASS_PREFIX}-${id}-${hovered.key}`)) ||
        (!hovered && active_name && el.classList.contains(`${CLASS_PREFIX}-${id}-${name}`))
      ) {
        el.style[css_property] = '1';
      } else {
        el.style[css_property] = '0.5';
      }
    });
    return;
  }
  document.body.querySelectorAll(`.${CLASS_PREFIX}-${id}`)?.forEach((el: HTMLElement) => {
    el.style[css_property] = '1';
  });
};

const Chart: React.FC<ChartProps> = ({
  type,
  legend_placeholder,
  datasets,
  compact,
  height,
  loading,
  children,
  onRefresh,
  time_options,
}) => {
  const id = useId().replace(/:/g, '');
  const theme = useTheme();
  const [hovered, setHovered] = useState<ChartHoveredState | null>(null);
  const [active_name, setActiveName] = useState<string | null>(null);
  const [unselected_datasets, setUnselectedDatasets] = useState<{ [key: string]: boolean }>({});
  const [show_legend_tooltip, setShowLegendTooltip] = useState(false);
  const [cached_datasets, setCachedDatasets] = useState(datasets);

  const [locked_state, setLockedState] = useState<{
    hovered?: ChartHoveredState;
    active_name?: string;
    lock_position: boolean;
    searched?: boolean;
  } | null>(null);

  let _hovered = locked_state?.hovered || hovered;
  const _active_name = locked_state?.active_name || active_name;

  useEffect(() => {
    if (datasets) {
      setCachedDatasets(datasets?.filter((d) => !unselected_datasets[d.key]));
    }
  }, [datasets, JSON.stringify(unselected_datasets)]);

  useEffect(() => {
    if (_hovered) {
      handleHovered(id, type, _hovered, _active_name);
    } else if (!_hovered && !_active_name) {
      const css_property = type === 'line' ? 'stroke-opacity' : 'opacity';
      document.body.querySelectorAll(`.${CLASS_PREFIX}-${id}`)?.forEach((el: HTMLElement) => {
        el.style[css_property] = '1';
      });
    }
  }, [_hovered, _active_name]);

  useEffect(() => {
    if (!_active_name) {
      setHovered(null);
    }
  }, [_active_name]);

  const data = useMemo(
    () => (!cached_datasets ? [] : formatDataForRechart(cached_datasets)),
    [cached_datasets],
  );

  const priority_display =
    cached_datasets &&
    cached_datasets[0] &&
    cached_datasets[0].data.filter((d) => !!d.priority_display).map((d) => d.x);

  let info = {};
  if (_active_name) {
    const info_data = data.find((d) => d.name === _active_name) as Data;
    if (info_data) {
      info = Object.entries(info_data)
        .sort((a, b) => (a[0] > b[0] ? -1 : 1))
        .reduce((obj, [key, value]) => {
          if (key === 'name') {
            return obj;
          }
          obj[key] = value;
          return obj;
        }, {});

      if (_hovered && !_hovered.tooltip) {
        info = { [_hovered.label]: info[_hovered.label], ...info };
      }
    }
  }

  if (hovered?.tooltip) {
    _hovered = {
      ...hovered,
      ...(_hovered || {}),
      key: hovered.key,
      name: _active_name || undefined,
    };
  }

  loading = loading || !datasets || datasets.length === 0;

  const _children = useMemo(
    () =>
      children({
        id,
        data,
        cached_datasets,
        datasets,
        hovered: _hovered,
        active_name: _active_name,
        locked_state,
        priority_display: priority_display || undefined,
        setHovered,
        setActiveName,
      }),
    [data, locked_state, JSON.stringify(unselected_datasets), type === 'line' && _hovered],
  );

  return (
    <WithLoader
      loading={loading}
      loading_element={
        <SkeletonChart
          type={type}
          legend_placeholder={legend_placeholder}
          height={height}
          loading={loading}
        />
      }
      long_loading_element={
        <Loader
          h={{ px: height || 374 }}
          message={
            'Loading your data is taking a bit longer than usual. It should be ready in a few moments.'
          }
        />
      }>
      {datasets && !compact && (
        <>
          <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
            <ScrollArea flex={{ gap: 3 }} p={3}>
              {datasets
                ?.sort((a, b) => legendSorter(a, b, order_by_by_metric_type[a.metric_type]))
                .map((dataset) => (
                  <ClickableArea
                    p={{ x: 2, y: 1 }}
                    block={false}
                    style={{
                      opacity: unselected_datasets[dataset.key] ? 0.5 : 1,
                    }}
                    key={dataset.key}
                    flex={{ align: 'center' }}
                    rounded
                    onClick={() => {
                      if (
                        cached_datasets &&
                        cached_datasets?.length <= 1 &&
                        !unselected_datasets[dataset.key]
                      ) {
                        return;
                      }
                      setUnselectedDatasets((prev) => {
                        if (prev[dataset.key]) {
                          delete prev[dataset.key];
                        } else {
                          prev[dataset.key] = true;
                        }
                        return { ...prev };
                      });
                    }}
                    onMouseEnter={() => {
                      setHovered({
                        key: dataset.key,
                        label: dataset.label,
                        monospace: dataset.monospace === false ? false : true,
                      });
                      setShowLegendTooltip(true);
                    }}
                    onMouseLeave={() => {
                      setHovered(null);
                      setShowLegendTooltip(false);
                    }}>
                    <LegendColor
                      color={
                        dataset.theme_color
                          ? theme.colors.surface.chart[dataset.theme_color]
                          : dataset.hex_color
                      }
                      m={{ r: 2 }}
                    />
                    <Text
                      monospace={dataset.monospace === false ? false : true}
                      subtitle={dataset.monospace === false ? true : false}
                      size="s">
                      {dataset.label}
                    </Text>
                  </ClickableArea>
                ))}
            </ScrollArea>
            <MouseTooltip visible={show_legend_tooltip}>
              <LegendTooltip hovered={_hovered!} datasets={datasets!} />
            </MouseTooltip>
            {(onRefresh || time_options) && (
              <Div flex={{ gap: 2, align: 'center' }} m={{ r: 4 }}>
                {time_options && time_options.time_units.length > 1 && (
                  <TextSwitch
                    options={time_options.time_units}
                    active={time_options.time_unit || time_options.time_units[0].key}
                    onSelect={(key) => time_options.onTimeUnitSelected(key as string)}
                  />
                )}
                {onRefresh && (
                  <Button
                    outline
                    icon="reload"
                    onClick={() => {
                      onRefresh && onRefresh();
                    }}
                  />
                )}
              </Div>
            )}
          </Div>
          <Divider />
        </>
      )}
      <Div p={{ r: 5, t: 7, b: 5 }}>
        <div
          style={{ opacity: loading ? 0.25 : 1 }}
          onMouseMove={(e) => {
            const target = document.elementFromPoint(e.clientX, e.clientY) as HTMLElement;
            if (
              !_hovered ||
              _hovered?.name === active_name ||
              target.classList.contains(`$[CLASS_PREFIX}-${id}`)
            ) {
              return;
            }
            setHovered(null);
          }}
          onMouseLeave={() => {
            setHovered(null);
            setActiveName(null);
            setLockedState(null);
          }}
          onClick={() =>
            setLockedState((prev: any) => {
              if (!prev) {
                return { active_name, lock_position: true };
              }
              if (prev.lock_position && prev.searched) {
                return { ...prev, active_name: undefined, lock_position: false };
              }
              if (prev.searched) {
                return { ...prev, active_name, lock_position: true };
              }
              return null;
            })
          }>
          <ResponsiveContainer height={height || 374}>{_children}</ResponsiveContainer>
          <MouseTooltip visible={!isEmpty(info)} locked={!!locked_state?.lock_position}>
            <ChartTooltip
              label={_active_name!}
              monospace={!!_hovered?.monospace}
              datasets={cached_datasets!}
              hovered={_hovered?.key}
              data={Object.entries(info)}
              locked={!!locked_state?.lock_position}
              setHover={setHovered}
              setLockedState={setLockedState as any}
            />
          </MouseTooltip>
        </div>
      </Div>
    </WithLoader>
  );
};

export default Chart;
