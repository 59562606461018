const generateNumberFromString = (str: string, min: number, max: number) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // convert to 32-bit integer
  }
  const range = max - min + 1;
  const num = (Math.abs(hash) % range) + min;
  return num;
};

export default generateNumberFromString;
