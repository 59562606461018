import { UIEventHandler, createRef } from 'react';
import styled, { css } from 'styled-components';
import useDidMount from '../../hooks/useDidUpdate';
import { Div, StyledUtilsProps } from './StyledUtils';

interface ScrollAreaProps extends StyledUtilsProps {
  children: React.ReactNode;
  show_scrollbar?: boolean;
  scroll_position?: number;
  onScroll?: UIEventHandler<HTMLElement>;
}

const StyledScrollArea = styled(Div)<{ show_scrollbar?: boolean }>(
  ({ theme, show_scrollbar = false }) => css`
    overflow: auto;

    ${!show_scrollbar &&
    css`
      &::-webkit-scrollbar {
        display: none;
      }
    `};
  `,
);

const ScrollArea: React.FC<ScrollAreaProps> = ({
  children,
  scroll_position,
  onScroll,
  ...props
}) => {
  const ref = createRef<HTMLDivElement>();

  useDidMount(() => {
    if (scroll_position && ref.current) {
      ref.current.scrollTop = scroll_position;
    }
  });

  return (
    <StyledScrollArea ref={ref} onScroll={onScroll} {...props}>
      {children}
    </StyledScrollArea>
  );
};

export default ScrollArea;
