import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import generateNumberFromString from '../../../utils/generateRandomNumberFromString';
import { Span, StyledUtilsProps } from '../helpers/StyledUtils';

const chart_colors = [
  '#99B8F5',
  '#7991C2',
  '#596B8F',
  '#A899F5',
  '#8579C2',
  '#62598F',
  '#D699F5',
  '#AA79C2',
  '#7D598F',
  '#F599E6',
  '#C279B6',
  '#8F5986',
  '#F599B8',
  '#C27991',
  '#8F596B',
  '#F5A899',
  '#C28579',
  '#8F6259',
  '#E6F599',
  '#B6C279',
  '#868F59',
  '#B8F599',
  '#91C279',
  '#6B8F59',
  '#99F5D6',
  '#79C2AA',
  '#598F7D',
  '#99E6F5',
  '#79B6C2',
  '#59868F',
  '#F5D699',
  '#C2AA79',
  '#8F7D59',
  '#99F5A8',
  '#79C285',
  '#598F62',
];

export const generateRandomChartColor = (str: string) => {
  return chart_colors[generateNumberFromString(str, 0, chart_colors.length - 1)];
};

interface Props extends StyledUtilsProps {
  id?: string;
  color?: string;
}

const StyledDot = styled(Span)<{
  color: string;
}>(
  ({ color }) => css`
    background-color: ${color};
    border-radius: 50%;
    height: 12px;
    width: 12px;
    display: inline-block;
  `,
);

const LegendColor = ({ id, color, ...other_props }: Props) => {
  const _color = useMemo(() => color || generateRandomChartColor(id || '1'), [id, color]);
  return <StyledDot color={_color} {...other_props} />;
};

export default LegendColor;
